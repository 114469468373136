<template>
  <div>
    <NavBar style="max-height=50px;margin-bottom: 10px;" :username="currentUserName" ></NavBar>


    <v-app style="background-color: #f5faff;" >
      <v-row>
        <v-col cols="2"></v-col>
        <v-col cols="10">
          <h3 style="text-align:left; margin-left:64px; margin-top:30px;font-weight:800">Edit  Appointment</h3>
          <div style="margin-left: 60px; margin-top:40px">
            <v-card style="padding: 20px;">
              <v-row>

                <div style="display: flex; flex-direction: row; width: 50%; margin: 20px; margin-bottom:1px;">
                  <div style="width: 50%" align="left">
                    <p style="font-weight: 600; font-size:10pt">Booking ID:</p >
                  </div>
                  <div style="width: 50%;font-size: 10pt;font-weight: 500;" align="left">
                    {{appointment_reference_number}}
                  </div>
                </div>
              </v-row>

              <v-row>
                <div style="display: flex; flex-direction: row; width: 50%; margin: 20px; padding-top:5px; margin-bottom:5px">
                  <div style="width: 50%;" align="left">
                    <p style="font-weight: 600; font-size:10pt">Name:</p >
                  </div>
                  <div style="width: 50%;font-size: 10pt;font-weight: 500;" align="left">
                    {{nominee}}
                  </div>
                </div>
              </v-row>

              <v-row>
                <div style="display: flex; flex-direction: row; width: 50%; margin: 20px; margin-bottom:5px;">
                 <div style="width: 50%;" align="left">
                  <p style="font-weight: 600; font-size:10pt">Date:</p >
                </div>
                <div style="width: 50%;font-size: 10pt;font-weight: 500;" align="left">
                  {{date}}
                </div>
              </div>
            </v-row>

            <v-row>
              <div style="display: flex; flex-direction: row; width: 50%; margin: 20px; margin-bottom:5px">
               <div style="width: 50%;" align="left">
                <p style="font-weight: 600; font-size:10pt">Time Slot:</p >
              </div>
              <div style="width: 50%;font-size: 10pt;font-weight: 500;" align="left">
                {{time_slot}}
              </div>
            </div>
          </v-row>


          <v-row>
            <div style="display: flex; flex-direction: row; width: 50%; margin: 20px;margin-top:0%">
              <div style="width: 50%;" align="left">
                <p style="font-weight: 600; font-size:10pt; margin-top:12px;">Payment:</p >
              </div>
              <div style="width: 50%" align="left">
                <v-select
                :items="payment_list"
                v-model="payment_status"
                item="payment_status"
                solo

                ></v-select>
              </div>
            </div>

          </v-row>


          <v-row>
            <div style="display: flex; flex-direction: row; width: 50%; margin: 5px;">

              <div style="width: 100%" align="center">
               <v-btn
               depressed
               color="primary"
               @click="saveAppointment"
               style="background-color: #1467BF;"
               >
               Edit
             </v-btn>
           </div>
         </div>
       </v-row>
     </v-card>
   </div>
 </v-col>
</v-row>
</v-app>


</div>
</template>

<script>
import NavBar from '@/views/navbar.vue';
import axios from "axios";
export default {
  name: 'CustomerHome',
  data() {
    return {
      booking_id: "",
      nominee: "",
      date: "",
      time_slot: "",
      payment_status: "PENDING",
      appointment_reference_number: 0,
      selected_doctor: "",
      doctors_list: [],
      payment_list: ['PAYMENT PENDING','PAID','REFUNDED','EXPIRED'],
      currentUser : "",
      currentUserName : "",
      isDisabled: false,
      mobileNumberRules: [
                        v => v > 0 || 'Mobile number cannot be negative',
                        ],


    };
  },
  components: {
    NavBar
  },
  methods: {
    gotoLoginPage() {
      var deleteData = {
        idToken : this.currentUser
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken",deleteData)
      .then((deleteResponse) => {
        this.$cookies.remove("token");
      })
      .catch((error) => {
      })
      localStorage.clear();
      this.$router.push({
        name: "AdminLogin",
      });
    },
    getAppointmentDetails(booking_id)
    {
      var booking_data = {
        "bookingId": booking_id,
        "token" : this.currentUser,
        "typeOfUser" : "ADMIN"
      };
      axios.post(process.env.VUE_APP_BACKEND_URL+"/viewParticularAppointment",booking_data)
      .then((getAppointmentDetailsResponse) => {
        var appointment_object = getAppointmentDetailsResponse.data.data;
        if(Object.keys(appointment_object).length > 0)
        {
          this.appointment_reference_number = appointment_object.appointment_reference_number;
          this.payment_status = appointment_object.payment_status;
          this.nominee = appointment_object.booked_for_name;
          this.date = appointment_object.date_of_appointment;
          this.time_slot = appointment_object.time_slot;
          this.selected_doctor = {"doctor_name":appointment_object.doctor_name,"_id":appointment_object.doctor_id};
          this.status = appointment_object.status;
          this.isDisabled = (this.status == "COMPLETED") ? true : false;
        }
        else
        {
          //DO NOTHING
        }
      })
      .catch((getAppointmentDetailsError) => {
        if(getAppointmentDetailsError.response.status == 401){
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name : "AdminLogin"
          });
        }
      })
    },
    saveAppointment()
    {
      var booking_id = this.booking_id;
      /*
      let bookingId = editRequest.body.bookingId
      let doctorId = editRequest.body.doctorId
      let doctorName = editRequest.body.doctorName
      let paymentStatus = editRequest.body.paymentStatus
      */
      var save_appointment_body = {
        "bookingId":booking_id,
        "paymentStatus": this.payment_status.toUpperCase(),
        "token" : this.currentUser,
        "typeOfUser" : "ADMIN"
      }

      axios.post(process.env.VUE_APP_BACKEND_URL+"/editAppointmentDetails",save_appointment_body)
      .then((saveAppointmentResponse) => {
        alert("Appointment edited successfully");
      })
      .catch((saveAppointmentError) => {
        if(saveAppointmentError.response.status == 401){
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name : "AdminLogin"
          });
        }
        else{
         window.alert("Oops something went wrong. Please try again later");
       }
     });
    }
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if(!this.currentUser){
      this.$router.push({
        name: "AdminLogin",
      });
    }
    else{
      var getAccessData = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType : "ADMIN"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAccessData)
      .then((getAdminResponse) => {
        this.currentUserName = getAdminResponse.data.data.admin_name;
        var params = this.$route.params;
        var booking_id = params['booking_id'];
        this.booking_id = booking_id;
        this.getAppointmentDetails(booking_id);
        var current_time = new Date(Date()).toLocaleString("en-us",{timeZone: "Asia/Yangon"});
      })
      .catch((getAdminError) => {
        if(getAdminError.response.status == 401){
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name : "AdminLogin"
          });
        }
      })
    }
  }

}

</script>
<style >
#facebook{
  display: none;
}
</style>